import { useEffect, useRef } from "react";
// 3rd parties
import L from "leaflet";

// owner
import CommonMapReact from "@containers/CommonMapReact/CommonMapReact";
import MarkerIcon from "@images/mapbox/images/marker-icon.png";
import TowerIcon from "@images/map/Cell_tower.svg";
import WifiIcon from "@images/map/Wi-Fi.svg";

import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";

function MapUpdateWifiCellularMismatch({ items, onClick }) {
  const map = useRef();
  const marker = useRef();
  const wifiHull = useRef();
  const wifiMarker = useRef();
  const towerOpenIdMarker = useRef();
  const towerLocationIqMarker = useRef();
  const towerHereMarker = useRef();

  useEffect(() => {
    if (!items || items.length == 0) {
      return;
    }
    let bounds = L.latLngBounds();
    if (marker.current) {
      map.current.removeLayer(marker.current);
    }
    if (wifiHull.current) {
      map.current.removeLayer(wifiHull.current);
    }
    if (wifiMarker.current) {
      map.current.removeLayer(wifiMarker.current);
    }
    if (towerOpenIdMarker.current) {
      map.current.removeLayer(towerOpenIdMarker.current);
      towerOpenIdMarker.current = null;
    }
    if (towerLocationIqMarker.current) {
      map.current.removeLayer(towerLocationIqMarker.current);
      towerLocationIqMarker.current = null;
    }
    if (towerHereMarker.current) {
      map.current.removeLayer(towerHereMarker.current);
      towerHereMarker.current = null;
    }

    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.wifiHull && item.wifiHull.length > 1) {
        if (item.wifiHull.length === 2) {
          wifiHull.current = L.polyline(
            item.wifiHull.map((c) => [c.x, c.y])
          ).addTo(map.current);
          bounds.extend(wifiHull.current.getBounds());
        } else {
          wifiHull.current = L.polygon(
            item.wifiHull.map((c) => [c.x, c.y])
          ).addTo(map.current);
          bounds.extend(wifiHull.current.getBounds());
        }
      }

      if (item.wifiLat && item.wifiLng) {
        wifiMarker.current = new L.marker([item.wifiLat, item.wifiLng], {
          icon: L.icon({ iconUrl: WifiIcon, iconAnchor: [23, 35] }),
        }).addTo(map.current);
        bounds.extend(wifiMarker.current.getLatLng());
      }

      
      if (item.cellLat && item.cellLng && item.wifiProvider == 'locationIQ' && towerLocationIqMarker.current == null) {
        towerLocationIqMarker.current = new L.marker([item.cellLat, item.cellLng], {
          icon: L.icon({ iconUrl: TowerIcon, iconAnchor: [23, 35] }),
        })
        .bindTooltip("("+ item.wifiProvider + ")")
        .addTo(map.current);
        bounds.extend(towerLocationIqMarker.current.getLatLng());
      }
      if (item.cellLat && item.cellLng && item.wifiProvider == 'openCellId' && towerOpenIdMarker.current == null) {
        towerOpenIdMarker.current = new L.marker([item.cellLat, item.cellLng], {
          icon: L.icon({ iconUrl: TowerIcon, iconAnchor: [23, 35] }),
        })
        .bindTooltip("("+ item.wifiProvider + ")")
        .addTo(map.current);
        bounds.extend(towerOpenIdMarker.current.getLatLng());
      }
      if (item.cellLat && item.cellLng && item.wifiProvider == 'here' && towerHereMarker.current == null) {
        towerHereMarker.current = new L.marker([item.cellLat, item.cellLng], {
          icon: L.icon({ iconUrl: TowerIcon, iconAnchor: [23, 35] }),
        })
        .bindTooltip("("+ item.wifiProvider + ")")
        .addTo(map.current);
        bounds.extend(towerHereMarker.current.getLatLng());
      }
    }


    bounds.isValid() &&
      map.current.fitBounds(bounds, { animate: true, maxZoom: 15 });

    map.current.on("click", function (event) {
      if (marker.current) {
        map.current.removeLayer(marker.current);
      }

      marker.current = new L.marker([event.latlng.lat, event.latlng.lng], {
        icon: L.icon({ iconUrl: MarkerIcon, iconAnchor: [13, 41] }),
        draggable: "true",
      });

      marker.current
        .bindTooltip("(" + event.latlng.lat + ", " + event.latlng.lng + ")")
        .addTo(map.current);

      marker.current.on("dragend", function (event) {
        marker.current.bindTooltip(
          "(" + event.target._latlng.lat + ", " + event.target._latlng.lng + ")"
        );
        onClick &&
          onClick({
            latitude: event.target._latlng.lat,
            longitude: event.target._latlng.lng,
          });
      });

      onClick &&
        onClick({
          latitude: event.latlng.lat,
          longitude: event.latlng.lng,
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <>
      <SntInfoPanel>
        {"Click to map to choose new location for cellular tower"}
      </SntInfoPanel>
      <CommonMapReact
        mapRef={map}
        height="600px"
        options={{
          zoomPosition: "topright",
          hideCurrentLocation: true,
          hideFullScreen: true,
          showGeozoneLayer: true,
          showNetworkLayer: true,
          searchBox: true,
        }}
      />
    </>
  );
}

export default MapUpdateWifiCellularMismatch;
