import { useEffect, useImperativeHandle, useState } from "react";

// Components
import DataUtils from "@/utils/DataUtils";
import useSaveView from "@/cleanup/containers/SaveViewContainer/useSaveView";
import SntTableViewCard, {
  SntConfigColumns,
  useColumnDefinition,
  useColumnFilter,
} from "@/components/SntTableViewCard";

const TableSaveView = ({
  allColumns,
  extraCols,
  disabled = false,
  isFloatingFooter,
  shared,
  noDataText,
  onRenderConfig,
  height,
  onCountClicked,
  onExtraColumnActionClicked,
  isDynamicDashBoard = false,
  isDisabbledGroup = false,
  isShowDecoratorColor,
  isShowSelectPageSize = true,
  isShowPaging = true,
  isShowConfigColumn = true,
  isDisabledCopyData = false,
  isDisabbledAddFilter = false,
  onUpdateData,
}) => {
  const [isShowConfigDialog, setShowConfigDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    updateTable,
    filterMap,
    tableOptions,
    setForceFilterAction,
    presentFilters,
    forceTableAction,
    setForceTableAction,
    getOldStructureOptionSettings,
    setServerColumnDef,
    refreshData,
  } = useSaveView();

  // Column definition
  const { allColumnDefs: columnsDef, serverColumnDef } = useColumnDefinition(
    tableOptions.queryObjectType,
    tableOptions.categories,
    tableOptions.preventCols,
    {
      originalCols: allColumns,
      extraCols: extraCols,
      includeDynamicColumn: tableOptions.includeDynamicColumn,
    }
  );

  const dataFilter = useColumnFilter(
    columnsDef,
    {
      defaultVisibleColumnKeys: tableOptions.visibleCols,
      defaultPageSize: tableOptions.pageSize,
      defaultSortBy: tableOptions.defaultSortBy,
      onCountClicked: onCountClicked,
    },
    {
      columnVisibleInOrder: tableOptions.columnVisibleInOrder,
      pageSize: tableOptions.pageSize,
      pageIndex: tableOptions.pageIndex,
      orderData: tableOptions.orderData,
      groups: tableOptions.groups,
    }
  );
  const {
    filter,
    setPageCount,
    getPageData,
    resetDefault,
    setTotalItems,
    setPaging,
    setVisibleColumnKeys,
  } = dataFilter;

  useEffect(() => {
    if (!forceTableAction) return;
    if (forceTableAction.ACTION_TYPE === "CHANGE_MORE_FILTER") {
      let { columnKeys } = forceTableAction;
      if (setVisibleColumnKeys) {
        if (filter) {
          setVisibleColumnKeys([
            ...filter.columns.map((item) => item.columnKey),
            ...columnKeys,
          ]);
        } else {
          setVisibleColumnKeys([
            ...columnKeys,
          ]);
        }
      }
    } else if (forceTableAction.ACTION_TYPE === "LOADING_DATA") {
      setLoading(true);
    } else if (forceTableAction.ACTION_TYPE === "UPDATE_DATA") {
      let {
        data,
        convertData,
        compactData,
        pageCount,
        totalItem,
      } = forceTableAction;
      let dataList = DataUtils.convertData({ data, convertData, compactData });
      setData(dataList);
      onUpdateData && onUpdateData(dataList);
      if (filter) {
        let pageNrOf = Math.ceil(pageCount / filter.pageSize);
        setPageCount(pageNrOf);
        setTotalItems(totalItem);
        if (filter.pageIndex >= pageNrOf) {
          setPaging(0, filter.pageSize);
        }
      }
      setLoading(false);
    } else if (forceTableAction.ACTION_TYPE === "ERROR_DATA") {
      console.log(forceTableAction);
    }
    setForceTableAction(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceTableAction]);

  const _getDataRequest = () => {
    const { start, limit, sort } = getPageData();
    let params = _getParamFormat(start, limit, sort);

    return { params, tableOptions };
  };

  const _getParamFormat = (
    start,
    limit,
    sort,
    queries,
    queryObjectType,
    searchQueryType
  ) => {
    const { requestedTableColumns: currentColumns, columns } = getPageData();
    let parsedSort = JSON.parse(sort);
    let orderData = [parsedSort[0].property, parsedSort[0].direction];

    return {
      maxResults: limit,
      sort: sort || "[]",
      query: queries || [],
      queryObjectType: queryObjectType,
      // currentColumns can a list of string, or list of group object
      columns: columns,
      requestedTableColumns: currentColumns.map((obj) => {
        if (typeof obj === "string") {
          return {
            columnKey: obj,
            order: obj === orderData[0] ? orderData[1] : null,
          };
        }
        return {
          ...obj,
        };
      }),
      searchQueryType: searchQueryType || "BASIC",
      startIndex: start,
    };
  };

  useEffect(() => {
    setServerColumnDef(serverColumnDef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverColumnDef]);

  useEffect(() => {
    if (!filter) return;
    const request = _getDataRequest();
    updateTable(
      {
        requestedTableColumns: request.params.requestedTableColumns,
        columns: request.params.columns,
        pageIndex: filter.pageIndex,
        pageSize: filter.pageSize,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const [data, setData] = useState([]);

  // Expose available functions for parents
  useImperativeHandle(shared, () => ({
    refresh: () => {
      refreshData();
    },
    resetDefault: () => {
      resetDefault && resetDefault();
      refreshData();
    },
    setLoading: (value) => {
      setLoading(value);
    },
    // setData: (data) => {
    //   setData(data);
    // },
    // setPageCount: (pageCount) => {
    //   setPageCount(Math.ceil(pageCount / filter.pageSize));
    // },
    // setTotalItems: (totalItem) => {
    //   setTotalItems(totalItem);
    // },
    updateDataTable: ({
      data,
      convertData,
      compactData,
      pageCount,
      totalItem,
    }) => {
      let dataList = DataUtils.convertData({ data, convertData, compactData });
      setData(dataList);
      if (filter) {
        let pageNrOf = Math.ceil(pageCount / filter.pageSize);
        setPageCount(pageNrOf);
        setTotalItems(totalItem);
        if (filter.pageIndex >= pageNrOf) {
          setPaging(0, filter.pageSize);
        }
      }
    },
  }));

  if (!dataFilter.filter) return <></>;

  let onConfigColumn = null;
  if (isShowConfigColumn) {
    onConfigColumn = () => {
      setShowConfigDialog(true);
    };
  }

  return (
    <div>
      {isShowConfigDialog && (
        <SntConfigColumns
          dataFilter={dataFilter}
          isShowConfigDialog={true}
          onDone={() => setShowConfigDialog(false)}
          onCancel={() => setShowConfigDialog(false)}
          isDisabbledGroup={isDisabbledGroup}
          optionsSettings={tableOptions.optionsSettings}
          defaultSortBy={tableOptions.defaultSortBy}
        />
      )}
      <SntTableViewCard
        dataFilter={dataFilter}
        data={data}
        onConfigColumn={onConfigColumn}
        loading={loading}
        noDataText={noDataText}
        isFloatingFooter={isFloatingFooter}
        isDisabbledGroup={isDisabbledGroup}
        isShowDecoratorColor={isShowDecoratorColor}
        isShowSelectPageSize={isShowSelectPageSize}
        isShowPaging={isShowPaging}
        onRenderConfig={onRenderConfig}
        isDynamicDashBoard={isDynamicDashBoard}
        height={height}
        onExtraColumnActionClicked={
          onExtraColumnActionClicked
            ? ({ columnKey }) => {
                let optionsSettings = getOldStructureOptionSettings();
                optionsSettings.tableSettings.columnVisibleInOrder = [
                  columnKey,
                ];
                onExtraColumnActionClicked(optionsSettings);
              }
            : null
        }
        isDisabledCopyData={isDisabledCopyData}
        isDisabbledAddFilter={isDisabbledAddFilter}
        restrictedFilters={tableOptions.restrictedFilters}
        onFilterClicked={(action, key, filterKeys, query) => {
          setForceFilterAction({ action, key, filterKeys, query });
        }}
        filterMap={filterMap}
        filterVisibleKeys={presentFilters}
      />
    </div>
  );
};

export default TableSaveView;
