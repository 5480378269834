import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";
import { saveAs } from "file-saver";

export default {
  findAllWithBasicField() {
    return apiClient.get(`/rest/sigfoxdevices/getAll/basicField`);
  },

  findOne(params, cancelToken) {
    return apiClient.get(`/rest/sigfoxdevices/${params.id}`, {
      cancelToken: cancelToken,
    });
  },

  findByIdentifier(params) {
    return apiClient.get(`/rest/sigfoxdevices/byIdentifier`, {
      params: params,
    });
  },

  findByInternalId(internalId) {
    return apiClient.get(`/rest/sigfoxdevices/internal/${internalId}`);
  },

  findBySerialOrLogisticsCode(serialOrLogisticCode) {
    return apiClient.post(
      `/rest/sigfoxdevices/bySerialOrLogisticCode/`,
      serialOrLogisticCode,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  prepareCopyFromProduction(serials, prodApiKey) {
    return apiClient.post(
      `/rest/sigfoxdevices/prepareCopyFromProduction?prodApiKey=${prodApiKey}`,
      serials,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  syncMessages(params) {
    return apiClient.get(`/rest/sigfoxdevices/${params.id}/syncmessages`);
  },

  getBasicById: function (params) {
    return apiClient.get(`/rest/sigfoxdevices/basic/${params.id}`);
  },

  getByBounds(params) {
    return apiClient.get(`/rest/sigfoxdevices/bounds`, { params: params });
  },
  getByOrg(orgId) {
    return apiClient.get(`/rest/sigfoxdevices/organisation/${orgId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  // deprecated??
  getSummary(params) {
    params.sort = null;
    return apiClient.get(`/rest/sigfoxdevices/state_summary`, params);
  },

  getDiagnosticOfSigfoxDevice(serial, params) {
    return apiClient.get(`/rest/sigfoxdevices/${serial}/diagnostic`, {
      params,
    });
  },

  create(params) {
    return apiClient.post(`/rest/sigfoxdevices`, JSON.stringify(params));
  },

  // TODO check params
  replaceBattery(id, params) {
    return apiClient.put(`/rest/sigfoxdevices/${id}/replaceBattery`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  lockFirmware(id) {
    return apiClient.put(`/rest/sigfoxdevices/${id}/lockFirmware`, null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  changeUserExpectedLifetime(id, params) {
    return apiClient.put(
      `/rest/sigfoxdevices/${id}/changeUserExpectedLifetime`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  markBatteryEmpty(id) {
    return apiClient.put(`/rest/sigfoxdevices/${id}/markBatteryEmpty`, null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  activate(id, sigfoxDeviceType, params) {
    return apiClient.put(
      `/rest/sigfoxdevices/${id}/activate${params}`,
      JSON.stringify(sigfoxDeviceType)
    );
  },

  update(id, params) {
    return apiClient.put(`/rest/sigfoxdevices/${id}`, JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateAdminPassword(id, newPassword) {
    return apiClient.put(
      `/rest/sigfoxdevices/${id}/adminPassword`,
      newPassword,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  destroy: "/rest/sigfoxdevices/{id}",

  delete(id) {
    return apiClient.delete(`/rest/sigfoxdevices/${id}`);
  },

  deleteTimeseries(id, msgId) {
    return apiClient.delete(
      `/rest/sigfoxdevices/${id}/sigfoxmessage/${msgId}/timeseries`
    );
  },

  deleteLocation(id, msgId) {
    return apiClient.delete(
      `/rest/sigfoxdevices/${id}/sigfoxmessage/${msgId}/location`
    );
  },

  adJustNetworkLocation(id, msgId, newLocation) {
    return apiClient.put(
      `/rest/sigfoxdevices/${id}/sigfoxmessage/${msgId}/location/adjust?lat=${newLocation.latitude}&lng=${newLocation.longitude}`,
    );
  },

  blockedWifiLocation(id, msgId) {
    return apiClient.post(
      `/rest/sigfoxdevices/${id}/blockedWifiLocation/${msgId}`
    );
  },

  findLatestStatus(params) {
    return apiClient.get(
      `/rest/stickntrack/feeds/location/latestupdate`,
      params
    );
  },

  getJourney(id, params) {
    return apiClient.get(`/rest/sigfoxdevices/" + id + "/journey`, params);
  },

  getOngoingJourney(id, params) {
    return apiClient.get(`/rest/sigfoxdevices/${id}/ongoingJourney`, params);
  },

  getBatteryLevels(id, params) {
    return apiClient.get(`/rest/sigfoxdevices/${id}/batteryLevels?${params}`);
  },

  getSigfoxMessages(id, params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/${id}/sigfoxMessages`,
        params
      )
    );
  },

  getGraph(id, params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/${id}/sigfoxMessages/graph`,
        params
      )
    );
  },

  getGraphByType(id, params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/${id}/sigfoxMessages/graphByType`,
        params
      )
    );
  },

  getJourneyTimeline(id, params, cancelToken) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/${id}/journeyTimeline`,
        params
      ),
      {
        cancelToken: cancelToken,
      }
    );
  },

  getJourneyLocations(id, params, cancelToken) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/${id}/journeyTimelineLocations`,
        params
      ),
      {
        cancelToken: cancelToken,
      }
    );
  },

  getAllLocationsOfSigfoxDevice(id, params, cancelToken) {
    return apiClient.put(
      `/rest/sigfoxdevices/${id}/allLocations`,
      JSON.stringify(params),
      {
        cancelToken: cancelToken,
      }
    );
  },

  deleteHistoryData(id, params) {
    return apiClient.put(
      `/rest/sigfoxdevices/delete_history_data/${id}`,
      params
    );
  },

  getCoverageInfo(sigfoxDeviceId) {
    let params = {};
    if (sigfoxDeviceId && sigfoxDeviceId != null)
      params.sigfoxDeviceId = sigfoxDeviceId;
    return apiClient.get(`/rest/sigfoxdevices/sigfoxCoverageInfo`, params);
  },

  getPrevNextDateWithTrack(id, params) {
    return apiClient.get(
      `/rest/sigfoxdevices/${id}/prevNextDateWithTrack`,
      params
    );
  },

  changeOwner(params) {
    return apiClient.put(
      `/rest/sigfoxdevices/changeOwner`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getErorLogs(deviceId) {
    return apiClient.get(`/rest/sigfoxdevices/error_logs/"${deviceId}`);
  },

  deleteAllErrorLogs(deviceId) {
    return apiClient.delete(`/rest/sigfoxdevices/error_logs/${deviceId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getDisengageById(sigfoxDeviceId) {
    return apiClient.get(`/rest/sigfoxdevices/disengage/${sigfoxDeviceId}`);
  },

  getBackendSigfoxDevice(localSigfoxDeviceInternalId) {
    return apiClient.get(
      `/rest/sigfoxdevices/backend/${localSigfoxDeviceInternalId}`
    );
  },

  getBackendSigfoxDeviceByInternalId(internalId) {
    return apiClient.get(
      `/rest/sigfoxdevices/backend/internalId/${internalId}`
    );
  },

  getBackendSigfoxDeviceByListInternalId(internalIds) {
    return apiClient.post(
      `/rest/sigfoxdevices/backend/internalId/`,
      JSON.stringify(internalIds),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getTimeSeriesDataByMetricTypeOfDevice(params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/timeSeriesByMetric`,
        params
      )
    );
  },

  getSigfoxMessageType() {
    return apiClient.get(`/rest/sigfoxdevices/sigfoxMessageType`);
  },

  getMetricType(success, error) {
    return apiClient.get(`/rest/sigfoxdevices/metricType`);
  },

  bulkActivateAndShip(params) {
    return apiClient.post(
      `/rest/sigfoxdevices/bulkActivationAndShipment`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getLastTimeSeriesByMetric(params) {
    return apiClient.get(
      `/rest/sigfoxdevices/lastTimeSeriesByMetric?id=${params.id}&metricType=${params.metricType}`
    );
  },

  getSupportedFwSettingPreset(params) {
    return apiClient.get(`/rest/sigfoxdevices/${params.serial}/fwPreset`);
  },

  changeImage(id, params) {
    return apiClient.put(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/changeImage/${id}`,
        params
      ),
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  changeIcon(id, params) {
    return apiClient.put(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/changeTrackerIcon/${id}`,
        params
      ),
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getFwParameter(params) {
    return apiClient.get(`/rest/sigfoxdevices/fwParams/${params.serial}`);
  },

  createFwParameter(serial, params) {
    return apiClient.post(
      `/rest/sigfoxdevices/fwSetting/${serial}?description=${encodeURIComponent(
        params.description
      )}`,
      JSON.stringify(params.settings),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  activatePredefinedSigfoxSubscription(params) {
    return apiClient.put(
      `/rest/sigfoxdevices/${params.id}/activate-predefined-sigfox-subscription`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getAllForReport(params) {
    let url = `/rest/sigfoxdevices/forReport?search=${params.search}&offset=${params.offset}&limit=${params.limit}`;
    if (params.serviceTypes.length > 0) {
      params.serviceTypes.forEach((serviceType) => {
        url += `&serviceTypes=${serviceType}`;
      });
    }

    if (params.deviceCategories?.length > 0) {
      params.deviceCategories.forEach((deviceCategory) => {
        url += `&deviceCategories=${deviceCategory}`;
      });
    }
    return apiClient.get(url);
  },

  getAllForAlertRule(params) {
    let uri = encodeURI(apiUltils.buildParamsListURI("forAlertRule", params));
    return apiClient.get("/rest/sigfoxdevices/" + uri);
  },

  hasRemoteSetting(id) {
    return apiClient.get(`/rest/sigfoxdevices/hasRemoteSetting/${id}`);
  },

  getByArea(id) {
    return apiClient.get(`/rest/sigfoxdevices/area/${id}`);
  },

  getActivationInfo(id) {
    return apiClient.get(`/rest/sigfoxdevices/${id}/activation_info`);
  },

  getAllOrderIds(success, error) {
    return apiClient.get(`/rest/sigfoxdevices/orderInfo0`);
  },

  getAllBoxIds() {
    return apiClient.get(`/rest/sigfoxdevices/boxInfo`);
  },

  updateComment(sigfoxDeviceId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/${sigfoxDeviceId}/comment`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getAggregatedDataSingleDevice(serial, params) {
    return apiClient.get(
      `/rest/sigfoxdevices/${serial}/data/aggregated/edge_temperature_alert${apiUltils.buildParamsListURI(
        "",
        params
      )}`
    );
  },

  validThirdPartyId(params) {
    return apiClient.get(
      `/rest/sigfoxdevices/validThirdPartyId${apiUltils.buildParamsListURI(
        "",
        params
      )}`
    );
  },

  getAlertCount(sigfoxDeviceId) {
    return apiClient.get(`/rest/sigfoxdevices/${sigfoxDeviceId}/alertCount`);
  },

  fetchStateChanges(sigfoxDeviceId) {
    return apiClient.get(
      `/rest/sigfoxdevices/manufacturing/fetchStateChanges/${sigfoxDeviceId}`
    );
  },

  getManufacturingDevices(params) {
    return apiClient.get(`/rest/sigfoxdevices/manufacturing`, {
      params: params,
    });
  },

  getManufacturingDevicesByOrder(params) {
    return apiClient.get(`/rest/sigfoxdevices/manufacturing/order`, {
      params: params,
    });
  },

  resetVbMaintenanceCounter(deviceId) {
    return apiClient.post(
      `/rest/sigfoxdevices/maintenance/resetCounter/${deviceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  searchEOFDevices(params) {
    return apiClient.post(
      `/rest/sigfoxdevices/filter/searchEOFDevices`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  countByFilters(schemeId, params) {
    let url = "/rest/sigfoxdevices/filter/count";
    if (schemeId) {
      url += "?schemeId=" + schemeId;
    }
    return apiClient.post(url, JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateLifecycleState(id, data) {
    return apiClient.put(`/rest/sigfoxdevices/${id}lifecycleState/${data}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getByIDsForBulk: function (params) {
    return apiClient.post(
      `/rest/sigfoxdevices/getByIDsForBulk`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getByIDsPost: function (params) {
    return apiClient.post(
      `/rest/sigfoxdevices/getByIDsPost`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  transferDevice: function (sigfoxDeviceId, organisationId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/${sigfoxDeviceId}/${organisationId}/transfer`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getDeviceSettings(params) {
    return apiClient.get(
      `/rest/sigfoxdevices/getDeviceSettings/${params.serial}`
    );
  },

  getBasicInfo(params) {
    let url = `/rest/sigfoxdevices/getBasicInfo?search=${params.search}&offset=${params.offset}&limit=${params.limit}&orgId=${params.orgId}&useChildOrg=${params.useChildOrg}&alertRuleType=${params.alertRuleType}`;
    if (params.serviceTypes?.length > 0) {
      params.serviceTypes.forEach((serviceType) => {
        url += `&serviceTypes=${serviceType}`;
      });
    }

    if (params.deviceCategories?.length > 0) {
      params.deviceCategories.forEach((deviceCategory) => {
        url += `&deviceCategories=${deviceCategory}`;
      });
    }

    return apiClient.get(url);
  },

  getSerialsForReplace(deviceSerial, params) {
    return apiClient.get(
      `/rest/sigfoxdevices/getDeviceSerialForReplace/${deviceSerial}?search=${params.search}&offset=${params.offset}&limit=${params.limit}`
    );
  },

  updateImei(deviceId, data) {
    return apiClient.put(`/rest/sigfoxdevices/${deviceId}/imei`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateBeaconId(deviceId, data) {
    return apiClient.put(`/rest/sigfoxdevices/${deviceId}/beaconId`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  generateBeaconId(deviceId) {
    return apiClient.get(`/rest/sigfoxdevices/${deviceId}/generateBeaconId`);
  },

  getIdsBySerials(params) {
    return apiClient.post("/rest/sigfoxdevices/idsBySerial", params);
  },

  updateIccid(deviceId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/${deviceId}/manufacturingInfo/iccid`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getFailureCategory() {
    return apiClient.get(`/rest/sigfoxdevices/failureCategory`);
  },

  getFailureReason() {
    return apiClient.get(`/rest/sigfoxdevices/failureReason`);
  },

  updateFailure(id, data) {
    return apiClient.put(`/rest/sigfoxdevices/updateFailure/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getDistributionOfFailedTracker() {
    return apiClient.get(`/rest/sigfoxdevices/distributionOfFailedTracker`);
  },

  getDistributionOfFailedTrackerByCategory(category) {
    return apiClient.get(
      `/rest/sigfoxdevices/distributionOfFailedTrackerByCategory/${category}`
    );
  },

  getFailedTrackerOvertimeData() {
    return apiClient.get(`/rest/sigfoxdevices/failedTrackerOvertimeData`);
  },

  getFailedTrackerTable() {
    return apiClient.get(`/rest/sigfoxdevices/failedTrackerTable`);
  },

  isSupportRouting(id) {
    return apiClient.get(`/rest/sigfoxdevices/${id}/isSupportRouting`);
  },

  getDevicesAtThisPoint(params, point, geozoneId) {
    return apiClient.post(
      `/rest/sigfoxdevices/inGeozone/${geozoneId}/atThisPoint/${point}`,
      params,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  findByDeviceIdentifier(orgId, deviceIdentifier) {
    return apiClient.post(
      `/rest/sigfoxdevices/byDeviceIdentifier?orgId=${orgId}`,
      deviceIdentifier,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getDeviceLifeCycleState() {
    return apiClient.get(`/rest/sigfoxdevices/deviceLifeCycleStates`);
  },

  getDebugInfo(id) {
    return apiClient.get(`/rest/sigfoxdevices/debugInfo/${id}`);
  },

  updateSystemTags(deviceId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/${deviceId}/updateSystemTags`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  updatePartnerTags(deviceId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/${deviceId}/updatePartnerTags`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  lock(deviceId, data) {
    return apiClient.put(
      `/rest/sigfoxdevices/lock/${deviceId}`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  exportAsCsvFile(params) {
    return apiClient
      .post(`/rest/sigfoxdevices/download/csvTemplateFile`, params, {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(blob, `Device_Settings.csv`);
      });
  },
  exportAsXlsFile(params) {
    return apiClient
      .post(`/rest/sigfoxdevices/download/xlsTemplateFile`, params, {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Device_Settings.xlsx`);
      });
  },

  importTrackerSettings(params) {
    return apiClient.post(`/rest/sigfoxdevices/importTrackerSettings`, params, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  registerExternalDevice(data) {
    return apiClient.post(`/rest/sigfoxdevices/registerExternalDevice`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateTotalDistanceInfo(id, params) {
    return apiClient.put(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/updateTotalDistanceInfo/${id}`,
        params,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
    );
  },

  updateTotalRunSecondsInfo(id, params) {
    return apiClient.put(
      apiUltils.buildParamsListURI(
        `/rest/sigfoxdevices/updateTotalRunSecondsInfo/${id}`,
        params,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
    );
  },

  countMonitoredAssets(orgId, monitoredAssets) {
    return apiClient.post(
      `/rest/sigfoxdevices/countMonitoredAssets?orgId=${orgId ? orgId : ""}`,
      monitoredAssets,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  getVodafoneServiceProfiles(deviceId) {
    return apiClient.get(`/rest/sigfoxdevices/getVodafoneServiceProfiles/${deviceId}`);
  },

  updateVodafoneServiceProfiles(deviceId, data) {
    return apiClient.put(`/rest/sigfoxdevices/updateVodafoneServiceProfiles/${deviceId}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
