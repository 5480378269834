export const ASSET_FILTERS_LIST = Object.freeze([
  "assetName",
  "assetTagsName",
  "locationByBound",
  "deviceCategory",
  "lastNetworkOverridePrecise",
  "alertRuleName",
  "decoratorColor",
]);

export const ASSET_FILTERS_LIST_ORG = [...ASSET_FILTERS_LIST, "organizations"];

export const ASSET_FILTERS_GEOZONE = [
  ...ASSET_FILTERS_LIST,
  "lastGeozone",
  "lastGeozoneTags",
];

export const ASSET_FILTERS_GEOZONE_ORG = [
  ...ASSET_FILTERS_LIST,
  "organizations",
  "lastGeozone",
  "lastGeozoneTags",
];

export const ASSET_FILTERS_SUEZ = [...ASSET_FILTERS_LIST, "filter_insertTime"];

export const ASSET_FILTERS_VISIBILITY = [
  ...ASSET_FILTERS_LIST_ORG,
  "visibilityFilterDtos",
];

export const forceMapFilters = (basic, useCalculatedBounds, bounds) => {
  let spatialFilter = {
    filterKey: "locationByBound",
    filterType: "SPATIAL",
    categoryId: "locationInfo",
    searchType: "STATIC",
    filterValue:
      useCalculatedBounds || !bounds
        ? { includeNoEmpty: true }
        : {
            xmin: bounds._southWest.lat,
            ymin: bounds._southWest.lng,
            xmax: bounds._northEast.lat,
            ymax: bounds._northEast.lng,
          },
  };

  let categoryFilter = {
    categoryId: "assetInfo",
    filterKey: "deviceCategory",
    filterType: "MULTI_SELECT_CHECKBOX",
    searchType: "STATIC",
    filterValue: {
      selectedValues: ["TRACKER"],
    },
  };

  // replace existing or add
  basic.query = [
    ...basic.query.filter((f) => f.filterKey !== "locationByBound"),
    spatialFilter,
  ];
  basic.query = [
    ...basic.query.filter((f) => f.filterKey !== "deviceCategory"),
    categoryFilter,
  ];

  ensureRequiredAssetMapColumnPresent(basic);
};

export const defaultMapRequiredColumns = [
  { columnKey: "lastNetworkLocationRadius", order: null },
  { columnKey: "currentLat", order: null },
  { columnKey: "currentLng", order: null },
  { columnKey: "name", order: null },
  { columnKey: "decoratorColor", order: null },
  { columnKey: "location", order: null },
  { columnKey: "lastBeaconName", order: null },
  { columnKey: "lastActivity", order: null },
];

export const ensureRequiredAssetMapColumnPresent = (basis) => {
  defaultMapRequiredColumns.forEach((col) => {
    if (basis.requestedTableColumns && !basis.requestedTableColumns.some((existingCol) => existingCol.columnKey === col.columnKey)) {
      basis.requestedTableColumns.push(col);
    }
  });

  delete basis.startIndex;
  delete basis.maxResults;
};
